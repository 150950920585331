import { Contrato, HttpResponseContrato } from './../model/contrato.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserUtil } from 'src/app/core/util/user.util';
import { Observable } from 'rxjs';
import { ConfigConst } from 'src/app/core/util/config.const';

@Injectable({
  providedIn: 'root',
})
export class ContratoService {
  resource = 'contratos';
  uri = ConfigConst.baseURL + this.resource;
  baseUri = `${ConfigConst.AWSImpostograma}/${ConfigConst.APP}/${this.resource}`;
  authorization = ConfigConst.AWSAuth;
  userUtil = new UserUtil();

  constructor(private http: HttpClient) {}

  getContratos(): Observable<Contrato[]> {
    return this.http.get<Contrato[]>(this.uri);
  }

  getByIdContrato(id: string): Observable<HttpResponseContrato> {
    return this.http.get<HttpResponseContrato>(
      this.baseUri + '/buscaContrato/' + id,
      {
        headers: {
          Authorization: this.authorization,
          db_name: localStorage.getItem('database') ?? '',
        },
      }
    );
  }

  createContrato(contrato: Contrato): Observable<Contrato[]> {
    return this.http.post<Contrato[]>(this.uri, contrato);
  }

  updateContrato(contrato: Contrato): Observable<Contrato[]> {
    return this.http.post<Contrato[]>(this.uri + '/update', contrato);
  }

  ListarRecuperacaoFeita(serviceId: string): Observable<any> {
    return this.http.get<any[]>(`${ConfigConst.API}Contrato/BuscaProduto`, {
      params: {
        Servico: serviceId,
      },
      headers: {
        Authorization:
          'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
        'Content-Type': 'application/json',
        email_login: localStorage.getItem('email_login'),
        cnpj_gestor: localStorage.getItem('cnpj_gestor'),
      },
    });
  }
}
