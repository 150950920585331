import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UsersComponent } from 'src/app/view/components/users/users.component';

@Injectable({providedIn: 'root'})
export class UserRegisterGuard implements CanDeactivate<UsersComponent> {

    canDeactivate(
        component: UsersComponent,
        currentRoute: ActivatedRouteSnapshot, 
        currentState: RouterStateSnapshot
    ): Observable<boolean>|Promise<boolean>|boolean {
        const allowUpdate = localStorage.getItem('allowUpdate');
        console.log('allowUpdate', allowUpdate === null);
        return allowUpdate === null;
    }
    
}