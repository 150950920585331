<h1 class="title">{{ titlepagina }}</h1>
<h4 class="text-center">{{ data.subtitle }}</h4>

<mat-vertical-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="firstFormGroup" label="Dados {{ daContabilidade }}">
    <form #g="ngForm" [formGroup]="firstFormGroup">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <mat-form-field class="col-lg-6 col-sm-6 col-xs-12" appearance="outline">
          <mat-label>CNPJ</mat-label>
          <input
            matInput
            formControlName="CNPJ_Gestor"
            mask="00.000.000/0000-00"
            [(ngModel)]="gestor.CNPJ_Gestor"
            placeholder="CNPJ"
            type="text"
            name="CNPJ_Gestor"
            required
          />
          <!-- <mat-icon class="icone" matSuffix (click)="buscarcnpj()">search</mat-icon> -->
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-6 col-xs-12" appearance="outline">
          <mat-label>Razão Social</mat-label>
          <input
            matInput
            formControlName="Razao_Social"
            [(ngModel)]="gestor.Nome"
            placeholder="Razão Social"
            type="Text"
            name="Razao_Social"
            maxlength="50"
            required
          />
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <mat-form-field class="col-lg-6 col-sm-6 col-xs-12" appearance="outline">
          <mat-label>CEP</mat-label>
          <input
            matInput
            formControlName="CEP"
            mask="00000-000"
            (focusout)="buscacep()"
            [(ngModel)]="gestor.CEP"
            placeholder="CEP"
            type="Text"
            name="CEP"
            required
          />
          <mat-icon class="icone" matSuffix (click)="buscacep()"
            >search</mat-icon
          >
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-6 col-xs-12" appearance="outline">
          <mat-label>Endereco</mat-label>
          <input
            matInput
            formControlName="Endereco"
            [(ngModel)]="gestor.Endereco"
            placeholder="Endereco"
            required
            maxlength="50"
          />
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <mat-form-field class="col-lg-6 col-sm-6 col-xs-12" appearance="outline">
          <mat-label>Número</mat-label>
          <input
            matInput
            formControlName="Numero"
            [(ngModel)]="gestor.Nro"
            placeholder="Numero"
            required
            maxlength="10"
          />
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-6 col-xs-12" appearance="outline">
          <mat-label>Complemento</mat-label>
          <input
            matInput
            formControlName="Complemento"
            [(ngModel)]="gestor.Complemento"
            placeholder="Complemento"
            maxlength="50"
          />
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <mat-form-field class="col-lg-6 col-sm-6 col-xs-12" appearance="outline">
          <mat-label>Cidade</mat-label>
          <input
            matInput
            formControlName="Cidade"
            [(ngModel)]="gestor.Cidade"
            placeholder="Cidade"
            required
            maxlength="50"
          />
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-6 col-xs-12" appearance="outline">
          <mat-label>UF</mat-label>
          <mat-select formControlName="UF" [(ngModel)]="gestor.UF">
            <mat-option *ngFor="let uf of ufList" [value]="uf">{{
              uf
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <mat-form-field class="col-lg-6 col-sm-6 col-xs-12" appearance="outline">
          <mat-label>Nome Contato</mat-label>
          <input
            matInput
            formControlName="NomeContato"
            [(ngModel)]="gestor.ContatoNome"
            placeholder="NomeContato"
            placeholder="Nome Contato"
            required
            maxlength="50"
          />
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-6 col-xs-12" appearance="outline">
          <mat-label>E-mail Contato</mat-label>
          <input
            matInput
            formControlName="EmailContato"
            [(ngModel)]="gestor.ContatoEmail"
            placeholder="EmailContato"
            placeholder="E-mail Contato"
            required
            email
            maxlength="80"
          />
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <mat-form-field class="col-lg-6 col-sm-6 col-xs-12" appearance="outline">
          <mat-label>Telefone</mat-label>
          <input
            matInput
            formControlName="Telefone"
            mask="(00) 0000-0000"
            [(ngModel)]="gestor.Telefone"
            placeholder="Telefone"
            type="Text"
            name="Telefone"
            maxlength="15"
            required
          />
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div class="col-sm-6">
          <mat-checkbox
            formControlName="IsentoICMS"
            [(ngModel)]="gestor.IsentoICMS"
            (change)="changeICMS()"
            >Isento ICMS</mat-checkbox
          ><br />
        </div>
        <div class="col-sm-6">
          <mat-checkbox formControlName="SNAC" [(ngModel)]="gestor.SNAC"
            >Optante Simples Nacional</mat-checkbox
          ><br />
        </div>
      </div>
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <mat-form-field class="col-lg-6 col-sm-6 col-xs-12" appearance="outline">
          <mat-label>Inscrição Municipal</mat-label>
          <input
            matInput
            formControlName="IM"
            [(ngModel)]="gestor.IM"
            placeholder="Se isento informar 0"
            type="Text"
            name="IM"
            minlength="1"
            maxlength="13"
            required
          />
          <mat-error *ngIf="firstFormGroup.get('IM').hasError('minlength')">
            Por favor insira ao menos 1 caracter, Se isento informar 0
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-6 col-xs-12" appearance="outline" *ngIf="ocultarIE">
          <mat-label>Inscrição Estadual</mat-label>
          <input
            matInput
            formControlName="IE"
            [(ngModel)]="gestor.IE"
            placeholder="Se isento informar 0"
            type="Text"
            name="IE"
            minlength="1"
            maxlength="14"
            required
          />
          <mat-error *ngIf="firstFormGroup.get('IE').hasError('minlength')">
            Por favor insira ao menos 1 caracter, Se isento informar 0
          </mat-error>
        </mat-form-field>
      </div>

      <div class="top">
        <!-- <button mat-button [disabled]="!g.valid" matStepperNext>Próximo</button> -->
        <button
          mat-raised-button
          color="primary"
          [disabled]="!g.valid"
          (click)="SalvarContabilidade()"
        >
          Salvar
        </button>
        <!-- <button id="nextstepper" mat-raised-button [disabled]="!g.valid" matStepperNext>Alterar Plano</button> -->
      </div>
    </form>
  </mat-step>
  <!-- <mat-step label="Alterar Plano">
        <div class="plan">    
            <div class = "example-ripple-container card mat-elevation-z4" id="plan2" (click)="selecionarPlanos(2,2,'349.00')">
                Até 02 CNPJs – R$ 349,00
            </div>
            <div class = "example-ripple-container card mat-elevation-z4" id="plan10" (click)="selecionarPlanos(10,10,'590.00')">
                De 02 a 10 CNPJs – R$ 590,00
            </div>
            <div class = "example-ripple-container card mat-elevation-z4" id="plan20" (click)="selecionarPlanos(20,20,'1062.00')">
                De 11 a 20 CNPJs – R$ 1.062,00
            </div>
            <div class = "example-ripple-container card mat-elevation-z4" id="plan30" (click)="selecionarPlanos(30,30,'1433.00')">
                De 21 a 30 CNPJs – R$ 1.433,00
            </div>
            <div class = "example-ripple-container card mat-elevation-z4" id="plan40" (click)="selecionarPlanos(40,40,'1720.00')">
                De 31 a 40 CNPJs – R$ 1.720,00
            </div>
            <div class = "example-ripple-container card mat-elevation-z4" id="plan50" (click)="selecionarPlanos(50,50,'1935.00')">
                De 41 a 50 CNPJs – R$ 1.935,00
            </div>
            <div class = "example-ripple-container card mat-elevation-z4" id="plan100" (click)="selecionarPlanos(100,100,'2700.00')">
                De 51 a 100 CNPJs – R$ 2.700,00
            </div>
            <p>
                * Clientes com até 5.000 produtos
            </p>
        </div>
        <div class="top">
          <button mat-raised-button color="primary" (click)="atualizarGestor('1')">Alterar</button>
        </div>  
    </mat-step> -->
</mat-vertical-stepper>
