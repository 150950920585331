import { UpgradeModalComponent } from './../upgrade-modal/upgrade-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { GestorService } from 'src/app/shared/services/gestor.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/googleanalytics.service';
import { AtualizarcontabilidadeComponent } from '../contabilidades/components/atualizarcontabilidade/atualizarcontabilidade.component';
import { ConfigConst } from 'src/app/core/util/config.const';
import { CustomValidators } from 'src/app/shared/validators/validators';
import { MessageService } from 'src/app/shared/services/message.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PagamentoService } from 'src/app/shared/services/pagamento.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
})
export class InicioComponent implements OnInit {
  imp: boolean = false;
  host = window.location.hostname;
  classic: string = '0';
  cupom: string = '0';
  site: string = '';
  APP: string = '';

  possuiSegregacao: boolean = false;

  isImpostocerto: boolean = false;

  tituloModuloAuditoria = ConfigConst.tituloModuloAuditoria;
  tituloModuloSegregacao = ConfigConst.tituloModuloSegregacao;
  tituloModuloConsulta = ConfigConst.tituloModuloConsulta;
  tituloModuloEmalote = ConfigConst.tituloModuloEmalote;
  tituloModuloRevisao = ConfigConst.tituloModuloRevisao;

  modulosDisponiveis = ['1', '2', '3', '4', '5', '6'];
  usuarioproibido: boolean = true;
  emalote: boolean = true;
  compartilhado: boolean = false;
  isSustentacao: boolean = false;
  static isModalOpen: boolean = false;

  constructor(
    public dialog: MatDialog,
    private GestorService: GestorService,
    private MessageService: MessageService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private PagamentoService: PagamentoService,
    private AuthService: AuthService
  ) {
    this.cupom = localStorage.getItem('Cupom');
    this.classic = localStorage.getItem('Classic');

    this.site = CustomValidators.primeiraLetraMaiscula(ConfigConst.APPSite);
    this.APP = ConfigConst.APP;

    this.possuiSegregacao = !!ConfigConst.modulo.segregacao;
  }

  ngOnInit(): void {
    this.isImpostocerto = ConfigConst.APP === 'impostocerto';
    this.isSustentacao = [
      ConfigConst.emailSustentacao,
      ConfigConst.emailSustentacaoComercial,
    ].includes(localStorage.getItem('email_login')) || localStorage.getItem('email_login')?.includes("@itworks.com");

    // Comentado por Matheus De Nigris

    if (!localStorage.getItem('email_login').includes('@impostograma.com.br')) {
      this.PagamentoService.getClienteCNPJ(
        localStorage.getItem('cnpj_gestor')
      ).subscribe(
        (res) => {
          if (res?.length > 0) {
            localStorage.setItem('id_super', res[0]['id_sacado_sac']);
            localStorage.setItem('email_super', res[0]['st_email_sac']);

            // this.MessageService.showMessage(
            //   'Você não possui dados de pagamento! Entrar em contato com o suporte!',
            //   'error-snackbar'
            // );
            // this.AuthService.logout();
          }
        },
        (err) => {
          console.error(err);
        }
      );
    }

    if (localStorage.getItem('user_perfil') === '8') {
      this.compartilhado = true;
      const Modulos = localStorage.getItem('modulos_compartilhado');
      if (Modulos) {
        this.modulosDisponiveis = this.modulosDisponiveis.filter((el) =>
          Modulos.includes(el)
        );
      }
    }

    if (!InicioComponent.isModalOpen) this.checaDadosGestor();

    if (this.host.includes('impostograma')) {
      this.imp = true;
    } else {
      this.imp = false;
    }
  }

  upgrade(url) {
    var valid = true;
    if (url == 'auditoria' || url == 'revisao-tributaria') {
      if (localStorage.getItem('Cupom') == '1') {
        document.location.href = url;
        valid = false;
      }
    }

    if (Number(localStorage.getItem('PlanoId')) != 4) {
      document.location.href = url;
      valid = false;
    }

    if (valid) {
      const dialogRef = this.dialog.open(UpgradeModalComponent, {
        width: '80vw',
        maxWidth: '500px',
        maxHeight: '90vh',
        data: {
          title: 'Aviso!',
          subtitle: ConfigConst.SubTituloUpgrade,
          text: ConfigConst.TextoUpgrade,
        },
      });
    }
  }

  validarAcesso(id) {
    this.checaDadosGestor();

    var url = '';
    let eventAction: string;

    switch (id) {
      case 1:
        url = 'emalote';
        eventAction = 'Acesso Modulo Emalote';
        break;
      case 2:
        url = this.isImpostocerto ? 'analise-consolidada' : 'auditoria';
        eventAction = 'Acesso Modulo Auditoria';
        break;
      case 3:
        url = 'revisao-tributaria';
        eventAction = 'Acesso Modulo Revisão Tributária';
        break;
      case 4:
        url = 'segregacao';
        eventAction = 'Acesso Modulo Segregação';
        break;
      case 5:
        url = 'consulta-livre-de-produtos';
        eventAction = 'Acesso Modulo Consulta Livre';
        break;
      case 6:
        url = 'recuperacao-simples-nacional';
        eventAction = 'Acesso Recuperação Simples Nacional';
        break;
    }

    this.googleAnalyticsService.sendEmitter({
      eventAction,
      eventCategory: 'Acesso',
      eventLabel: CustomValidators.validarModulo(),
    });

    document.location.href = url;
  }

  checaDadosGestor() {
    InicioComponent.isModalOpen = true;
    this.GestorService.getByIdGestor(
      localStorage.getItem('cnpj_gestor')
    ).subscribe(
      (res) => {
        // console.log(res);
        if (res.body.length > 0) {
          localStorage.setItem(
            'notificacao',
            res.body['Notificacao'] ? '1' : '0'
          );

          localStorage.setItem('planoDescricao', res.body[0]['Descricao']);

          if (
            res.body[0]['Endereco'] == '' ||
            res.body[0]['Nro'] == null ||
            res.body[0]['Cidade'] == ''
          ) {
            if (this.dialog.openDialogs && this.dialog.openDialogs.length > 0) {
              return false;
            }

            const dialogRef = this.dialog.open(
              AtualizarcontabilidadeComponent,
              {
                width: '90vw',
                maxWidth: '800px',
                maxHeight: '90vh',
                disableClose: true,
                data: {
                  cnpjs: localStorage.getItem('cnpj_gestor'),
                  title: 'Atualização de Dados Cadastrais',
                  subtitle:
                    'por favor verifique se os seus dados estão corretos antes de prosseguir',
                  consultaCNPJ: true,
                },
              }
            );
            dialogRef.afterClosed().subscribe((result) => {
              this.checaDadosGestor();
            });
          }
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
