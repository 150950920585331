import { AuthService } from './shared/services/auth.service';
import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ConfigConst } from './core/util/config.const';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = ConfigConst.APPSite;

  mostrarMenu: boolean = false;

  constructor(private AuthService: AuthService, public router: Router) {}

  ngOnInit() {
    this.AuthService.mostrarMenu.subscribe(
      (mostrar: boolean) => (this.mostrarMenu = mostrar)
    );
  }
}
