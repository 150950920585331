import {
  Gestor,
  GestorContribuintePOC,
  HttpResponseGestor,
  PayloadCadastraContrato,
} from 'src/app/shared/model/gestor.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserUtil } from 'src/app/core/util/user.util';
import { Observable } from 'rxjs';
import { ConfigConst } from 'src/app/core/util/config.const';

@Injectable({
  providedIn: 'root',
})
export class GestorService {
  resource = 'gestores';
  uri = ConfigConst.baseURL + this.resource;

  baseUri = `${ConfigConst.AWSImpostograma}/${ConfigConst.APP}/${this.resource}`;
  authorization = ConfigConst.AWSAuth;
  userUtil = new UserUtil();
  url = ConfigConst.API;
  App = ConfigConst.APP;
  baseUrl = ConfigConst.AWSImpostograma;
  AWSAuth = ConfigConst.AWSAuth;
  token = 'YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy';

  constructor(private http: HttpClient) {}

  getGestores(): Observable<Gestor[]> {
    return this.http.get<Gestor[]>(this.uri);
  }

  getByIdGestor(id: string): Observable<HttpResponseGestor> {
    return this.http.get<HttpResponseGestor>(
      this.baseUri + '/buscaGestor/' + id,
      {
        headers: {
          Authorization: this.authorization,
          db_name: localStorage.getItem('database') ?? '',
        },
      }
    );
  }

  createGestor(gestor: Gestor): Observable<Gestor[]> {
    return this.http.post<Gestor[]>(this.uri, gestor);
  }

  updateGestor(gestor: Gestor): Observable<Gestor[]> {
    return this.http.post<Gestor[]>(this.uri + '/update', gestor);
  }

  atualizarGestor(gestor: Gestor): Observable<Gestor[]> {
    return this.http.post<Gestor[]>(this.uri + '/atualizar', gestor);
  }

  createNewGestor(gestor: Gestor): Observable<Gestor[]> {
    return this.http.post<Gestor[]>(this.uri + '/new', gestor);
  }

  desabilitarGestor(gestor: string, status: string): Observable<Gestor[]> {
    return this.http.post<Gestor[]>(this.uri + '/desabilitar', {
      CNPJ_Gestor: gestor,
      Status: status,
    });
  }

  criaContratoGestor(
    payload: PayloadCadastraContrato,
    CNPJ_Gestor: string,
    Email: string
  ): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/${this.App}/contratos/cadastraContratoCompleto`,
      payload,
      {
        headers: {
          CNPJ_Gestor: CNPJ_Gestor,
          Email_Login: Email,
          Authorization: this.AWSAuth,
        },
      }
    );
  }

  criaGestorPOC(payload: GestorContribuintePOC): Observable<any> {
    return this.http.post<any>(`${this.url}/Gestor/CreateGestorPOC`, payload, {
      headers: {
        Authorization: `Bearer ${this.token}`,
        'Content-Type': 'application/json',
        email_login: localStorage.getItem('email_login'),
        cnpj_gestor: localStorage.getItem('cnpj_gestor'),
      },
    });
  }
}
