import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { EtapaInfoComponent } from './etapa-info.component';
import { EtapaInfoRoutingModule } from './etapa-info-routing.module';

// COMPONENTS
import { GridChart } from '../../model/grid.model';
import { GridEtapa } from '../../model/grid-etapa.model';

// SERVICES
import { UploadsService } from '../../services/uploads.service';
import { DataService } from '../../services/data-chart.service';
import { DataEtapaService } from '../../services/data-etapa.service';

// MATERIAL
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [EtapaInfoComponent],
  imports: [
    RouterModule,
    EtapaInfoRoutingModule,
    MatCardModule,
    MatTableModule,
  ],
  providers: [DataEtapaService, DataService, UploadsService],
  exports: [EtapaInfoComponent],
})
export class EtapaInfoModule {}
