import { UsuarioCompartilhado } from './../model/usuariocompartilhado.model';
import { UserContribuinte } from './../model/usercontribuinte.model';
import { ConfigConst } from './../../core/util/config.const';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root',
})
export class UsuariocompartilhadoService {
  uriUser = ConfigConst.baseURL + 'usuarioscompartilhados/';
  uriCont = ConfigConst.baseURL + 'contusuariocompartilhado/';

  API = ConfigConst.API;

  AuthApi = 'YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy';

  constructor(
    private router: Router,
    private http: HttpClient,
    private messageService: MessageService
  ) {}

  criarUsuario(users: UserContribuinte): Observable<UserContribuinte[]> {
    return this.http.post<UserContribuinte[]>(this.uriUser + 'criar', users);
  }

  atualizarUsuario(users: UserContribuinte): Observable<any> {
    return this.http.post<any>(
      `${this.API}UsuarioCompartilhado/Update`,
      users,
      {
        headers: {
          Authorization: `Bearer ${this.AuthApi}`,
          CNPJ_Gestor: localStorage.getItem('cnpj_gestor'),
        },
      }
    );
  }

  buscarUsuario(email: string): Observable<any[]> {
    return this.http.post<any[]>(this.uriUser + 'buscar', {
      EmailLogin: email,
    });
  }

  desabilitarUsuario(email: string, status: string): Observable<any[]> {
    return this.http.post<any[]>(this.uriUser + 'desabilitar', {
      EmailLogin: email,
      Status: status,
    });
  }

  criarContribuinteUsuario(contribuinteusuario: any): Observable<any[]> {
    return this.http.post<any[]>(this.uriCont + 'criar', contribuinteusuario);
  }

  atualizarContribuinteUsuario(
    contribuinteusuario: UsuarioCompartilhado
  ): Observable<any> {
    return this.http.post<any>(
      `${this.API}UsuarioCompartilhado/UpdateContribuinte`,
      contribuinteusuario,
      {
        headers: {
          Authorization: `Bearer ${this.AuthApi}`,
          CNPJ_Gestor: localStorage.getItem('cnpj_gestor'),
        },
      }
    );
  }

  buscarContribuinteUsuario(email: string): Observable<UsuarioCompartilhado[]> {
    // console.log(this.uriCont + "buscar/"+email);
    return this.http.get<UsuarioCompartilhado[]>(
      this.uriCont + 'buscar/' + email
    );
  }
}
