import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigConst } from 'src/app/core/util/config.const';

export interface SendEmitterProps {
  eventAction: string;
  eventLabel?: string | null;
  eventCategory: string;
}

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  baseUrl = ConfigConst.API;

  constructor(private http: HttpClient) {}

  public sendEmitter(payload: SendEmitterProps, subscribeAction?: () => void) {
    if (ConfigConst.APP.toLowerCase() !== 'impostograma') {
      return false;
    }

    if (
      !localStorage.getItem('cnpj_gestor') ||
      !localStorage.getItem('email_login')
    ) {
      return;
    }

    this.http
      .post<any>(
        `${this.baseUrl}/Usuario/Evento`,
        {
          Action: payload.eventAction,
          Modulo: payload.eventLabel,
          TypeAction: payload.eventCategory,
        },
        {
          headers: {
            Authorization:
              'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
            CNPJ_Gestor: localStorage.getItem('cnpj_gestor'),
            Email_Login: localStorage.getItem('email_login'),
          },
        }
      )
      .subscribe(
        (data) => {
          console.log('Evento Disparado: ', payload);
        },
        (err) => {
          console.log(err);
        }
      ).add(() => {
        if (subscribeAction) {
          subscribeAction()
        }
      });
  }

  public setUser() {

  }
}
