import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ConfigConst } from '../../core/util/config.const';
import { UserUtil } from 'src/app/core/util/user.util';
import { data } from 'jquery';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OverrideService {
  url = ConfigConst.baseURL + 'override/';
  resource = 'override/produto/';
  uri = ConfigConst.baseURL + this.resource;
  userUtil = new UserUtil();

  token = 'YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy';
  baseUrlApi = ConfigConst.API;

  AWSAuth = ConfigConst.AWSAuth;
  baseUrl = ConfigConst.AWSImpostograma;
  constructor(private httpClient: HttpClient) {}

  //PRODUTOS EMITENTES
  getProdutos($contribuintes): Observable<any[]> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any[]>(
      `${
        this.uri
      }getproduto/${$contribuintes}/${this.userUtil.getCnpjGestor()}/${server}/${database}`
    );
  }

  listarProdutos(cnpj, cpf): Observable<any> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any[]>(
      `${this.url}produto/listar/${cnpj}/${cpf}/${server}/${database}`
    );
  }

  cadastrarProduto(Produto): Observable<any> {
    let Server = {
      server: localStorage.getItem('server'),
      database: localStorage.getItem('database'),
    };
    return this.httpClient.post<any>(this.url + 'produto/cadastrar', {
      Produto,
      Server,
    });
  }

  atualizarProduto(Produto): Observable<any> {
    let Server = {
      server: localStorage.getItem('server'),
      database: localStorage.getItem('database'),
    };
    return this.httpClient.post<any>(this.url + 'produto/atualizar', {
      Produto,
      Server,
    });
  }

  deletarProduto(Produto): Observable<any> {
    let Server = {
      server: localStorage.getItem('server'),
      database: localStorage.getItem('database'),
    };
    return this.httpClient.post<any>(this.url + 'produto/deletar', {
      Produto,
      Server,
    });
  }

  checarCNPJCPF(cpf): Observable<any[]> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any[]>(
      this.url + 'participante/meus/' + cpf + '/' + server + '/' + database
    );
  }

  checarCadProduto(cpf, cnpj): Observable<any[]> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any[]>(
      this.url +
        'cadproduto/' +
        cpf +
        '/' +
        cnpj +
        '/' +
        server +
        '/' +
        database
    );
  }

  checarCFOP(cpf): Observable<any[]> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any[]>(
      this.url + 'cfop/' + cpf + '/' + server + '/' + database
    );
  }

  // PARTICIPANTES EMITENTES

  getParticipantes($contribuintes): Observable<any[]> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any[]>(
      `${
        this.url
      }participanteoverride/getparticipante/${$contribuintes}/${this.userUtil.getCnpjGestor()}/${server}/${database}`
    );
  }

  cadastrarParticipante(Participante): Observable<any> {

    return this.httpClient.post<any>(
      `${this.baseUrlApi}Participante/ParticipanteOverride`,
      Participante,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
        },
      }
    );
  }

  atualizarParticipante(Participante): Observable<any> {
    let Server = {
      server: localStorage.getItem('server'),
      database: localStorage.getItem('database'),
    };
    return this.httpClient.post<any>(
      this.url + 'participanteoverride/atualizar',
      { Participante, Server }
    );
  }

  deletarParticipante(Participante): Observable<any> {
    let Server = {
      server: localStorage.getItem('server'),
      database: localStorage.getItem('database'),
    };
    return this.httpClient.post<any>(
      this.url + 'participanteoverride/deletar',
      { Participante, Server }
    );
  }

  listarParticipantes(cnpj, cpf): Observable<any> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any[]>(
      `${this.url}participanteoverride/listar/${cnpj}/${cpf}/${server}/${database}`
    );
  }

  //Cenários Override

  getCenarios($contribuintes): Observable<any[]> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any[]>(
      `${
        this.url
      }cenario/getcenario/${$contribuintes}/${this.userUtil.getCnpjGestor()}/${server}/${database}`
    );
  }

  cadastrarCenario(Cenario): Observable<any> {
    let Server = {
      server: localStorage.getItem('server'),
      database: localStorage.getItem('database'),
    };
    return this.httpClient.post<any>(this.url + 'cenario/cadastrar', {
      Cenario,
      Server,
    });
  }

  atualizarCenario(Cenario): Observable<any> {
    let Server = {
      server: localStorage.getItem('server'),
      database: localStorage.getItem('database'),
    };
    return this.httpClient.post<any>(this.url + 'cenario/atualizar', {
      Cenario,
      Server,
    });
  }

  deletarCenario(Cenario): Observable<any> {
    let Server = {
      server: localStorage.getItem('server'),
      database: localStorage.getItem('database'),
    };
    return this.httpClient.post<any>(this.url + 'cenario/deletar', {
      Cenario,
      Server,
    });
  }

  listarCenario(cnpj, cpf): Observable<any> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any[]>(
      `${this.url}cenario/listar/${cnpj}/${cpf}/${server}/${database}`
    );
  }

  //LOGS
  cadastrarLog(
    Tabela: string,
    Atividade: string,
    msg: string = ''
  ): Observable<any> {
    var today = new Date();
    var date =
      today.getFullYear() +
      '-' +
      ('0' + (today.getMonth() + 1)).slice(-2) +
      '-' +
      today.getDate();
    var time =
      today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    var dateTime = date + ' ' + time;

    const formData = new FormData();
    formData.append('EmailLogin', this.userUtil.getEmailLogin());
    formData.append('DataHora', dateTime);
    formData.append(
      'IPOrigem',
      String(localStorage.getItem('user_ip'))?.substring(1, 20)
    );
    formData.append('Tabela', Tabela);
    formData.append('Atividade', Atividade);
    formData.append('msg', msg);

    return this.httpClient.post<any>(this.url + 'log/cadastrar', formData);
  }

  //Referencial SPED
  buscarReferencial(
    descricao: any,
    ncm: string,
    fabricacaoPropria: boolean,
    considerarNCM: boolean
  ): Observable<any> {
    let payload: any = {
      fabricacaoPropria,
      palavras: descricao,
    };

    if (considerarNCM) {
      payload.ncm = ncm;
    }

    let headers = new HttpHeaders({
      Authorization:
        'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
      'Content-Type': 'application/json',
      CNPJ_Gestor: localStorage.getItem('cnpj_gestor'),
      Email_Login: localStorage.getItem('email_login'),
    });

    let data = JSON.stringify(payload);

    return this.httpClient.post<any>(
      ConfigConst.API + 'Produto/ProdutoColecao',
      data,
      { headers }
    );
  }

  buscaProdutosColecao(
    descricao: any,
    ncm: string,
    fabricacaoPropria: boolean,
    considerarNCM: boolean
  ): Observable<any> {
    let payload: any = {
      fabricacaoPropria,
      query: descricao.join(' '),
    };

    if (considerarNCM) {
      payload.NCM = ncm;
    }

    return this.httpClient.post<any>(
      `${this.baseUrl}/revisao/buscaProdutosColecao`,
      payload,
      {
        headers: {
          Authorization: this.AWSAuth,
        },
      }
    );
  }

  errorHandler(error: HttpErrorResponse) {
    alert('Alerta: ' + error.error.status.mensagem);
    // this.MessageService.showMessage(error.error.status.mensagem,'error-snackbar');
    return throwError(error);
  }

  //atualizar CadProduto Referencial
  atualizarSped(sped, produto, contribuinte, ean): Observable<any> {
    const formData = new FormData();
    formData.append('CNPJ_Contribuinte', contribuinte);
    formData.append('emaillogin', localStorage.getItem('email_login'));
    formData.append('Produto', produto);
    formData.append('ProdutoReferencialSPED', sped);
    formData.append('EAN', ean);
    formData.append('server', localStorage.getItem('server'));
    formData.append('database', localStorage.getItem('database'));

    return this.httpClient.post<any>(
      this.url + 'cadproduto/atualizar',
      formData
    );
  }

  execPrIGProcessamentos(chave): Observable<any> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    // console.log(this.url+'execPrIGProcessamentos/'+chave);
    return this.httpClient.get<any>(
      this.url +
        'execPrIGProcessamentos/' +
        chave +
        '/' +
        server +
        '/' +
        database
    );
  }

  verDocumento(tipo, codigo): Observable<any> {
    var headers = new HttpHeaders({
      Authorization:
        'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
      'Content-Type': 'application/json',
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });

    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any>(
      `${ConfigConst.Emalote}/api/Documento/Select/` + tipo + '/' + codigo,
      { headers }
    );
  }

  verCFOP(codigo): Observable<any> {
    codigo = btoa(codigo);
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any>(
      this.url + 'vercfop/' + codigo + '/' + server + '/' + database
    );
  }

  verNCM(codigo): Observable<any> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    console.log(this.url + 'verncm/' + codigo + '/' + server + '/' + database);
    return this.httpClient.get<any>(
      this.url + 'verncm/' + codigo + '/' + server + '/' + database
    );
  }

  AtividadedoEmitente(): Observable<any> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any>(
      this.url + 'AtividadedoEmitente/' + server + '/' + database
    );
  }

  RegimeEspecialdoEmitente(): Observable<any> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any>(
      this.url + 'RegimeEspecialdoEmitente/' + server + '/' + database
    );
  }

  RegimeEspecialdoDestinatario(): Observable<any> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any>(
      this.url + 'RegimeEspecialdoDestinatario/' + server + '/' + database
    );
  }

  AtividadedoDestinatario(): Observable<any> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any>(
      this.url + 'AtividadedoDestinatario/' + server + '/' + database
    );
  }

  DestinacaodoProduto(): Observable<any> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any>(
      this.url + 'DestinacaodoProduto/' + server + '/' + database
    );
  }

  Operacao(): Observable<any> {
    let server = btoa(localStorage.getItem('server'));
    let database = localStorage.getItem('database');
    return this.httpClient.get<any>(
      this.url + 'Operacao/' + server + '/' + database
    );
  }
}
