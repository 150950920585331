import { DialogData } from './../../../view/components/usuariocontribuinte/usuariocontribuinte.component';
import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    public dialog: MatDialog, 
  ) { }

  ngOnInit(): void {
  }

  desc(){
    const dialogConfirm = this.dialog.open(DialogDataExampleDialog, {
      maxWidth: "90vw",
      maxHeight: "90vh",
      data: {
        title: "",
        message: "" 
      }
    });
    
  }

} 

@Component({
  selector: 'dialog-data-example-dialog',
  template: '<h1>Alterações!</h1><br/>'+
  '<ul>'+
  '<li>Dev 1.1.46'+
  '<ul>'+
  '<li>Menu Integrações</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.45'+
  '<ul>'+
  '<li>Melhoria na tela de Auditoria</li>'+
  '<li>Correções exporte Segregação e Revisão Tributária</li>'+
  '</ul>'
  +'</li>'+
  '<ul>'+
  '<li>Dev 1.1.44'+
  '<ul>'+
  '<li>Lazyloading</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.43'+
  '<ul>'+
  '<li>Melhorias RVI</li>'+
  '<li>Melhorias Auditoria</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.42'+
  '<ul>'+
  '<li>Emalote</li>'+
  '<li>Cockpit de Vendas</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.41'+
  '<ul>'+
  '<li>Nova Segregação</li>'+
  '<li>Ajustes Relatório Auditoria</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.40'+
  '<ul>'+
  '<li>Ajuste Simulador de Operações</li>'+
  '<li>Status Moderação</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.39'+
  '<ul>'+
  '<li>Inclusão consultas versão angular</li>'+
  '<li>Solicitar fundamentação consultas angular</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.38'+
  '<ul>'+
  '<li>Inclusão calculo de espaço emalote</li>'+
  '<li>Aviso financeiro</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.37'+
  '<ul>'+
  '<li>Inclusão Função Ver DACTE - Emalote Browser Documentos</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.36'+
  '<ul>'+
  '<li>Ajustes e Melhorias das Tabelas</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.35'+
  '<ul>'+
  '<li>Filtros Segregação</li>'+
  '<li>Inclusão dos campos IPI PIS/COFINS no Simulador de operações </li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.34'+
  '<ul>'+
  '<li>Modulos</li>'+
  '<li>Respeitar funcionalidades</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.33'+
  '<ul>'+
  '<li>Contribuintes por Módulos</li>'+
  '<li>Alterar CRUD contribuinte por API</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.32'+
  '<ul>'+
  '<li>Atualização emalote layout</li>'+
  '<li>Atualização visão situacional </li>'+
  '<li>Correção de Funções</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.31'+
  '<ul>'+
  '<li>Correções nas conexões aos bancos de dados</li>'+
  '<li>Incusão dos campos adicionais ao emalote nos contribuintes</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.1.30'+
  '<ul>'+
  '<li>Alterar Dinâmica de conexões aos bancos de dados</li>'+
  '<li>Incusão do campo Regime Federal Simulador de Operações</li>'+
  '<li>Correção Referecial Ean</li>'+
  '<li>Alterações nos botões relatório Auditoria</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.29'+
  '<ul>'+
  '<li>Incusão do campo Regime Federal</li>'+
  '<li>Incluir link CFOP e NCM para os relatórios auditoria</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.28'+
  '<ul>'+
  '<li>Incusão de novos itens no relatório Auditoria</li>'+
  '<li>Incluir Layout Dinâmico Auditoria</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.27'+
  '<ul>'+
  '<li>Incusão de item Com EANs Divergentes no relatório Auditoria</li>'+
  '<li>Exportar itens com Eans Divergentes</li>'+
  '<li>Remover validação para acessar itens ignorados não eans</li>'+
  '<li>Exporte configurável nos relatórios RVI</li>'+
  '<li>Nova versão Simulador de Operações</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.26'+
  '<ul>'+
  '<li>Adição do Campo Ean no exporte relatório auditoria</li>'+
  '<li>Correção do Formulário Simulador Dizeres e Ordem dos campos</li>'+
  '<li>Inclusão de função Consulta de Produtos sem Ean Referencial</li>'+
  '<li>Edição dos Dizeres Relatório auditoria</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.25'+
  '<ul>'+
  '<li>Novo layout dashboard</li>'+
  '<li>Menus dinâmicos</li>'+
  '<li>Uploads de arquivos na tela segregação</li>'+
  '<li>Validação contribuinte segregação ilimitado</li>'+
  '<li>habilitar opção de upgrade de plano para módulos não contrados no plano classic</li>'+
  '<li>Simulador de Operações</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.24'+
  '<ul>'+
  '<li>Editor de planilha</li>'+
  '<li>Correção da api export xmls</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.23'+
  '<ul>'+
  '<li>Corrigir paginação dos status da auditoria</li>'+
  '<li>Incluir icone no botão ver relatório da auditoria</li>'+
  '<li>Na ação do botão ver relatório incluir open-new-tab</li>'+
  '<li>Incluir o bloco de perdas tributárias no export do relatório</li>'+
  '<li>Incluir botões no relatório auditoria</li>'+
  '<li>Alterar o menu, desabilitar revisão e auditoria e deixar apenas como tópico e incluir novos menus no mesmo patamar dos outros</li>'+
  '<li>Criar uma nova dash com os principais recursos do impostograma</li>'+
  '<li>Alterar o nome do export pdf - incluir cnpj contribuinte</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.22'+
  '<ul>'+
  '<li>IVA Ajustado remover a palavra ajustado</li>'+
  '<li>Incluir as colunas AnalisadoNCM e AnalisadoCEST nas tabelas de erros</li>'+
  '<li>Incluir Documento de Emissão Própria: SIM/NÃO nos cenários</li>'+
  '<li>Incluir as novas colunas no relatório xmls</li>'+
  '<li>Alterar nome do pdf relatório</li>'+
  '<li>Alinhar as colunas dos cenários </li>'+
  '<li>Ajuste css coluna valores relatório</li>'+
  '<li>Alterar Backend para a inclusão das novas colunas</li>'+
  '<li>Realização de Testes </li>'+
  '<li>Ajustes Excel </li>'+
  '<li>Deploy impostocerto app</li>'+ 
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.21'+
  '<ul>'+
  '<li>Incluir Otimização na Associação de Eans</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.20'+
  '<ul>'+
  '<li>Incluir Regra de inserção e update contribuintes</li>'+
  '<li>Restringir exclusão para Plano Free</li>'+
  '<li>Remover o aviso de Perdas e Passivo Tributário para NFE Entrada</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.19'+
  '<ul>'+
  '<li>Incluir EAN no referencial</li>'+
  '<li>Revisão dos textos Upload Auditoria</li>'+
  '<li>Correção do apontamento criar conta</li>'+
  '<li>selecionar Gestor automáticamente em adicionar Usuário</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.18'+
  '<ul>'+
  '<li>No quadro dos totais das divergencias, colocar um filtro para filtrar nos detalhes (somente na chave, no Produto ou no EAN) e filtrar "geral"</li>'+
  '<li>Alterar máscara data e remover os segundos</li>'+
  '<li>Remover o status de filtro upload de dentro do select e deixar fora</li>'+
  '<li>Combo de contribuintes por intelisense ( a medida que digita vai posicionando )</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.17'+
  '<ul>'+
  '<li>Alterar os overrides para o botao salvar, fazer compulsoriamente delete e insert</li>'+
  '<li>Nas telas se esta vindo por atalho (carregando a chaveacessodoc e o processsamentos (tipo e ano/mes) deixar visivel botão de exclusão</li>'+
  '<li>Disponibilizar subida em Lote ( Template de CSV )</li>'+
  '<li>Mostrar o cabeçalho e uma barra de atividade "Carregando" e mostrar os paineis apos a carga total</li>'+
  '<li>Mover o botão de "Definir Cenario Proprio" para o Final do form ( depois do cenario considerado do Produto)</li>'+
  '<li>No mostrar o XML ao clicar na chave abrir como Modal (generoso)</li>'+
  '<li>Relatorio da Auditoria a exportação para o Excel colocar os cenarios em colunas (conforme o print)</li>'+
  '<li>Em todas os componentes de grid, trocar o "items per page" por "Itens por pagina"</li>'+
  '<li>Botão exportar para XLSX todo o calculo ( aba para PIS, para ICMS, para Faltantes )</li>'+
  '<li>Trazer o ano e mes corrente como default no filtro</li>'+
  '<li>Corrigir carregamento dos contruibuintes Auditoria</li>'+
  '<li>Altera a listagem dos combos Cenário override a fim de dinamizá-los</li>'+
  '<li>Renomear exports</li>'+
  '<li>Corrigir Listagem ICMS</li>'+
  '<li>Colocar botão export para produtos únicos e exportar resultado da procedure</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.16'+
  '<ul>'+
    '<li>Tirar o botão da listagem da segregação e passar para a Calculadora</li>'+
    '<li>Na tela da calculadora nao faz sentido a opcao "todos" nos filtros de ano e mes</li>'+
    '<li>Na grid dos Uploads da Auditoria o status "3-Processados com Sucesso" deixar visivel Botão "Enviar o Upload para o Móeudlo da Revisão Tributária!</li>'+
    '<li>Adicionar mais um Status no Upload da auditoria que será o "5 - Processado com Sucesso e Enviado a Revisao Tributária"</li>'+
    '<li>Abrir o XML para as NFEs.</li>'+
    '<li>No Click das CFOPs que aparecem na "CFOP Divergente" mostrar modal ou notificação com o resultado</li>'+
    '<li>No Click dos NCMs que aparecem an "NCM Divergente" mostrar modal ou notificação com o resultao</li>'+
    '<li>Na Modal do Cenario incluir mais uma sessão</li>'+
    '<li>Atalhos Override</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.15'+
  '<ul>'+
    '<li>Correção Insert contribuintes - Ajuste;</li>'+
    '<li>Trocar palavra Contabilidade por Gestor</li>'+
    '<li>Colocar log nas querys</li>'+
    '<li>Verificar cst listagem cenário</li>'+
    '<li>Colocar tamanho máximo nos uploads</li>'+
    '<li>Verificar a mensagem de upload, só mostrar upload concluído quando o insert for feito na tabela</li>'+
    '<li>Adicionar ean na tabela de divergencia relatório</li>'+
    '<li>Caracter errado cenário - relatório auditoria</li>'+
    '<li>Listagem duplicada em intens analisados auditoria (tentar reproduzir erro)</li>'+
    '<li>Adicionar coluna tipo movimento nos status 9 e 11 auditoria sendo: E - Entrada, S - Saída, A - Entrada/Saída</li>'+
    '<li>Colocarr no cabeçalho do relatório Tipo do conteúdo + Tipo de Movimento ex: PDV - Saída</li>'+
    '<li>Adição de campo pesquisa para as Grid de auditoria e relatório</li>'+
    '<li>Alterar texto Observação da página relatório</li>'+
    '<li>Alterar texto Filtrar para Filtrar resultados na auditoria - OK</li>'+
    '<li>Separar inputs cenários por cor</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.14'+
  '<ul>'+
    '<li>Com divergência - Acrescentar Análise Concluída com Divergência de Impostos</li>'+
    '<li>Sem divergência - Acrescentar Análise Concluída sem Divergência de Impostos</li>'+
    '<li>Verificar Uploads Gráfico, não está listando</li>'+
    '<li>Correção de Rotas relatório auditoria</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.13'+
  '<ul>'+
    '<li>Ao logar direcionar a dashboard para a tela de auditoria</li>'+
    '<li>Atualizar mensagem do upload para Trasmissão iniciada e mensagem quando o upload IGUploadArq for cadastrado com sucesso.</li>'+
    '<li>Trocar a ordem do menu (Auditoria e depois Revisão)</li>'+
    '<li>Ao clicar nas caixas da auditoria abrir a sanfona e atualizar (consultar auditoria)</li>'+
    '<li>Ver filtros da auditoria</li>'+
    '<li>Verificar modal de volta do relatório</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.12'+
  '<ul>'+
    '<li>Alterar apontamento do relatório auditoria para dev</li>'+
    '<li>Trocar o texto "Erro" no cabeçalho do form modal que mostra o detalhe das divergências</li>'+
    '<li>Adicionar o Titulo na coluna "Quantidades"</li>'+
    '<li>Só normalizar os titulos deixando iguais. Melhor deixar no grafico igual "Estatistica dos Produtos Auditados por Situação"</li>'+
    '<li>Tudo ambiente de produção. Precisa ver se ja foi corrigido e precisa atualizar.</li>'+
    '<li>Sessão 4 O Primeiro grafico só tem o total (nao faz sentido) tem que ser as fatias das operações (titulo errado tambem) e o grafico debaixo nao tem titulo e se puder ficar mais alinhado com o box "Destinação" a direita melhor (se for possivel)</li>'+
    '<li>sessão 5 Note que esta repetindo tipos</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.11'+
  '<ul>'+
    '<li>melhorias de layout</li>'+
    '<li>inclusão de funcionalidade por plano</li>'+
    '<li>correção de bugs dev 1.0.10</li>'+
    '<li>remoção do change Gestor dashboard</li>'+
    '<li>limitador de CNPJs</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.10'+
  '<ul>'+
  '<li>0. No DashBorad da REvisao:<br/>'+
	'- Tirar o CARD de Uploads<br/>'+	
	'- Deixar o Grafico e a GRID em uma SANFONA unica, filtrado por dEfault "ONTEM". Entrar no dashboard por default comm a SANFONA encolhida!'+
  '</li>'+

  '<li>1. Botão de trocar o "Relacionar ID Imposto" (rodape do FORM de detalhes do Imposto).<br/>'+
	'- Na grid alterar o texto do botão para "Associar"<br/>'+
  '- Tirar do Rodape do Form e colocar ao lado do campo "ID Imposto"<br/>'+
	'- Regra para poder Funcionar dentro do Form:<br/>'+
		'- Imposto Certo.<br/>'+
			'- Pode Alterar LOGANDO a operação<br/>'+ 
		'- Imposto Grama.<br/>'+
			'- Pode Alterar se o produto não tiver EAN ( branco ou SEM GTIN) - Logando a operação.  (mata a observação de que não pode ser desfeita)'+
  '</li>'+	
  '<li>2. Relatorio de Auditoria<br/>'+
	'- Mostrar até o Resumo dos Erros<br/>'+
	'- Ao clicar no ERRO, abrir popup com a GRID paginando 20 linhas por default<br/>'+
	'- Arrumar a sensibildiade de alteração da paginação (refresh da grid ao trocar as linhas por pagina)<br/>'+
	'- A sessão 4.6 da procedure esta entrando no 4.7 da tela (exemplo R$ 429441.07 da Casa Publicadora ), e deslogando todos os campos<br/>'+
	'- A Sessão 4.6 é a unica que se vier valor zero ( ex. PDV ), não é para mostrar o item.<br/>'+
	'- No Popup do "veja o Cenario", tem caractere "Estranho" no item Atividade do Destinatario ( ex: Atividade	N-NÃO CONTRIBUINTE  )<br/>'+
	'- Todas as porcentaem zero deve estar mascarado "0.00" ( 2 virgula decimais, mesmo zerado )<br/>'+
  '</li>'+
  '<li>3. Relatorio da Auditoria, mudar de PDV para um Planilha Excel XLSX. Se possivel:<br/>'+
	'- Cada erro em uma ABA, ou<br/>'+
	'- Em uma unica aba, colocando na 1a. coluna o "ERRO" e ja subindo um filtro nela.'+
  '</li>'+
  '<li>4. Menu<br/>'+ 
	'- As 3 opções e opções "override" devem ficar "DEBAIXO" (condicionado) da Opção Auditoria<br/>'+
		'- Imposto Grama. Visivel apenas:<br/>'+
			'- Alterar Indicadores<br/>'+
			'- Relacionar Produto Emitente<br/>'+
		'- Imposto Certo. Visivel Todos os 3'+
	'</li>'+
  '<li>5. No Form de Alterar Indicadores Participante<br/>'+
		'- Mudar chamada no menu para "Redefinir Atividade Economica Participantes"<br/>'+
		'- Mudar titulo do Fomr para "Redefinir Atividade Economica Participantes"<br/>'+
		'- botão de Adicionar, mudar texto para "Adicionar Redefinição de Participante"<br/>'+
		'- No form Titulo do campo  CNPJ/CPF Emitente alterar para apenas "CNPJ"<br/>'+
		'- Apos digitar o CNPJ/CPF, ja validar pesquisando na tabela e não somente no botão "Cadastrar" ( mudar para "Salvar" )'+
  '</li>'+	
  '<li>6. No Form Relacionar Produto Emitente<br/>'+
		'- botão de Adicionanr, mudar o teto para "Adicionar Relacionamento Produto Emitente"<br/>'+
		'- No form Titulo do campo  CNPJ/CPF Emitente alterar para apenas "CNPJ"<br/>'+
		'- Apos digitar o CNPJ/CPF, ja validar pesquisando na tabela <br/>'+
		'- Apos digitar "Meu Codigo de Produto", ja validar pesquisando na Tabela<br/>'+
		'- alterar o titulo do botão "Cadastrar"  para "Salvar"<br/>'+
	'</li>'+
  '<li>7. No Form Adicoanra Cenario<br/>'+
		'- No form Titulo do campo  CNPJ/CPF Emitente alterar para apenas "CNPJ"<br/>'+
		'- Apos digitar o CNPJ/CPF, ja validar pesquisando na tabela <br/>'+
		'- Apos digitar "Meu Codigo de Produto", ja validar pesquisando na Tabela. Se Existir e tiver EAN, ja traz o EAN do Cadastro e Não deixa DIGITAR!!!<br/>'+
		'- Nos campos do tipo "Tributação", colocar combo com as opções para o campo CST, e trocar o titulo do campo de "CST do ICMS" para "CST/CSOSN do ICMS"<br/>'+
			'- Organizar a lista da seguinte maneira:<br/>'+
				'- "Regime Normal (CST)"<br/>'+
					'(os coidgos que 2 digitos)<br/>'+
				'- "Simples Nacional (CSOSN)<br/>'+
					'( os codigos que tem 3 digitos )<br/>'+
		'- alterar o titulo do botão "Cadastrar"  para "Salvar"'+
  '</li>'+
  '<li>8. Nos Contribuintes'+
    'Adicionar 3 campos para envio de RVI automático'+
    'Correção de backend'+
  '</li>'+
  '<li>9. Disparo de emails automáticos'+
    'criação de script cron'+
    'salvar emails enviados na tabela'+
  '</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.9'+
  '<ul>'+
    '<li>melhorias de layout</li>'+
    '<li>inclusão da auditoria + relatório</li>'+
    '<li>correção de bugs dev 1.0.8</li>'+
    '<li>inclusão alterar indicadores</li>'+
    '<li>inclusão cenário próprio</li>'+
    '<li>inclusão relacionar produto emitente</li>'+
  '</ul>'
  +'</li>'+
  '<li>Dev 1.0.8'+
  '<ul>'+
    '<li>26-  (Este usuário já está cadastrado como administrador) perfil = 5</li>'+
    '<li>27- Não habilitar trial para novas contabilidades ? (segunda contabilidade não entra como trial)</li>'+
    '<li>2 - Não recebeu o e-mail de ativação? ative aqui - na pagina de login (pedir nome, email, telefone e senha) se acertar três, Ativar usuário e logar automaticamente para a dashboard</li>'+
    '<li>Quando excluir contabilidade o que acontece? - desativar contabilidade no impostograma e cancelar o plano no superlogica e boletos futuros (boletos pendentes permanece para pagamento)</li>'+
    '<li>41 - criar função para contabilizar o upload e inserir no progress bar e no spinnerbar para que eles acompanhe o progresso do upload</li>'+
    '<li>Juntar passo 1 e 2 de enviar arquivo CSV</li>'+
    '<li>39- Indentifique o arquivo PVA ICMS que contenha os produtos que você quer revisar; Compacte este arquivo com winzip ou winrar e você estrá pronto para começar. (juntar passo 1 e 2 e deixar no passo 1)</li>'+
    '<li>42- Trocar o nome do grafico para Uploads dos arquivos + sugerir (Somente ontem default, ultimos 7 dias, 10 dias, 15 dias e 30 dias) - refazer o elemento por solicitação de remodelagem de negócio</li>'+
    '<li>43- Não recarregar quando perder a internet (remover tela loading no filtro dos gráficos e ativar o progress bar da tabela enquanto ela é carregada)</li>'+
    '<li>44- Produto sem EAN ou inválido. Associe ao EAN IT Works para ter impostos, tirar o envermelho</li>'+
    '<li>Colocar legenda "ICMS" no botão PVA</li>'+
    '<li>45- corrigir a legenda do grafico (como uma tabela) segundo imagem enviada com layout corrigido - Criação de gráfico angulado + criação de tabela para legendas </li>'+
    '<li>46- data de base do imposto na label da coluna (alterar title da tabela Data referência para Data base do imposto, nunca escolher uma data superior hoje+5dias) - tabela de revisão + alterar title data referencia para data base do imposto</li>'+
    '<li>47- Colocar Botão de "ver detalhes" para mostrar mais (add coluna na tabela revisao)</li>'+
    '<li>48- Deixar setinha fixada como na label da grade para reordenar</li>'+
    '<li>49- No selecionar arquivo precisa ser um botão azul</li>'+
    '<li>50- Ao desativar um contribuinte,  ele só poderá ser reativado manualmente. mensagem =  (um cotribuinte desativado, pode ser reativado no menu contribuintes desde que o plano comporte. )</li>'+
    '<li>51- Colocar icone csv, json, xml no export</li>'+
    '<li>52- Quando trial apenas PVA uploads e só poderá ter 1 contribuinte cadastrado (Mensagem: Você está em periodo de Trial, efetive a contratação para usar todos os recursos), As demais contabilidade não habilitadas para trial, apenas com a confirmação do pagamento (Em trial não é possivel cadastra manualmente, apenas pelo PVA)</li>'+
    '<li>53- IGgestor(status= trial) - Atenção com o impacto nas outras operações (Salvar como T, na ativação por e-mail, verificar pagamento para mudar para ativo se pago, se não =t)</li>'+
    '<li>54- Todas as notificações sem temporizador, só sai C22no x (Trocar Refrash por atualização NG init)</li>'+
    '<li>55- Mesmo usuário criando, se já existe, enviar para login. mensagem = "Usuário já cadastrado! Por favor efetue o login"</li>'+
    '<li>57- Tirar menu esquerdo e colocar em todas as telas um botão para voltar a revisão + criar navbar</li>'+
    '<li>58- Ajustar botões (+ readapitativo para outras resoluções)</li>'+
    '<li>59- Contrato de uso</li>'+
    '<li>60- Termo do aceite e Privacidade</li>'+
    '<li>61- Termo cookes (vou usar seus dados de navegação)</li>'+
    '</ul>'
  +'</li>'+ 
  '<li>Dev 1.0.7'+
  '<ul>'+
    '<li>alterar o tamanho do campo ultima alteração para mostrar todo o texto</li>'+
    '<li>Aviso de contabilidade pendente de cadastro também na tela inicial</li>'+
    '<li>colocar progress bar contando os caractes da senha e mostra pro usuário que a senha atingiu o limite de caracteres </li>'+
    '<li>erro no cadastro de nova contabilidade integração superlógica</li>'+
    '<li>colocar no topo do site pagar se tiver faturas em aberto senão não exibir nada </li>'+
    '<li>quando estiver para contratar bloquear a revisão mesmo quando for editar - esta pulando o bloqueio </li>'+
    '<li>Receber variaveis de planos do site</li>'+
    '<li>Receber variavel e-mail no novo plano</li>'+
    '<li>Consultar Revisão | Nova Revisão</li>'+
    '<li>Colocar title nos campos refencia e ultima alteração (foto thiago)  está aparecendo apenas em cima do calendario, precisa ser na label toda</li>'+
    '</ul>'
  +'</li>'+ 
  '<li>Dev 1.0.6'+
  '<ul>'+
    '<li>Correção da inserção do contribuinte</li>'+
    '<li>Correção exibição contribuinte</li>'+
    '<li>Cadastrar usuário no superlógica - nova conta (pedir os dados obrigatórios) e acrescentar os outros dados no menu (Meus dados) e atualizar conforme informados na base e no superlógica (todo pelo impostogram, uma senha só)</li>'+
    '<li>Remove a função de pesquisa cnpj sefaz do cadastro de contabilidade </li>'+
    '<li>Dar a opção de selecionar qtd de parcelas de adesão (contratação + alteração do plano)</li>'+
    '<li>Modal selecionar contabilidade - deixar texto = Bem-vindo, alterar o texto do select para selecione uma contabilidade.</li>'+
    '<li>se tiver apenas uma contabilidade e ela estiver pendente - Abrir modal para editar contabilidade na tela inicial. - alterar texto do modal ("Complete o cadastro da sua contabilidade"); inlcuir o botão de salvar ao lado do alterar plano (não deixar salvar dois  planos Iguais tabela contratos);</li>'+
    '<li>verificar as tabelas duplicando contrato no banco, quando edita a contabilidade</li>'+
    '<li>deixar os 2 botões no modal contabilidade (Salvar + Altera plano (mostrar o plano));</li>'+
    '<li>Cadastrar contribuinte após edição da contabilidade abrir o modal com a tela de uploads na vertical (Parabéns! Os dados da sua contabilidade foram cadastrados! Cadastre seus contribuintes para começar) tela desenho do thiago</li>'+
    '<li>Cadastro de contribuinte atravez do PVA, padronizar os selects fiscais para o padrão do banco (foto thiago whatsapp)</li>'+
    '<li>Colocar o icone de mostrar senha na tela de login e nova - conta</li>'+
    '<li>Criar um gráfico - situação da revisão com base no status do produto ( 3, x e 2) e filtrar a grid pelos elementos clicados</li>'+
    '<li>colocar disable em campos da tela de mostrar produto da revisão, tirar opção de editar</li>'+
    '<li>colocar as lables do campo sempre na parte superior</li>'+
    '<li>Quando selecionar contribuinte em RVI - limpar a grid da consulta (tela RVI) sempre que clicar buscar no banco as contabilidades (botão de refresh)</li>'+
    '<li>mascara de campos PIS-COFINS (thiago confirmar) verificar o retorno da procedure</li>'+
  '</ul>'
  +'</li>'+ 
  '<li>Dev 1.0.5'+
  '<ul>'+
    '<li>Mensagem de Upgrade - Você está efetuando a troca do seu plano - As próximas mensalidades serão R$ XXX,XX</li>'+
    '<li>Mascarar/formatação na hora de exibir numero no csv</li>'+
    '<li>Avisar administrador que usuário já foi cadastrado na contabilidade A, deseja cadastra-lo na contabilidade B</li>'+
    '<li>Contratar plano no superlógica pelo menu contabilidades</li>'+
    '<li>(Contribuinte excedente entra como N + alerta e quando muda o contrato ativa como A) possibilitar cadastro manual</li>'+
    '<li>reativação dos contribuintes em "N" quando upgrade do Plano.</li>'+
    '<li>Atualização downgrade de plano + mudança de status para contribuintes excedentes</li>'+
    '<li>Criação de rotas e estruturas no backend</li>'+
  '</ul>'
  +'</li>'+ 
  '<li>Dev 1.0.4'+
  '<ul>'+
    '<li>Deixar a tela do login menor e centralizado horizontal e vertical + fundo azul claro.</li>'+
    '<li>Criar margin nas telas, deixar menor.</li>'+
    '<li>Certificado - sinalizar linha com o background amarelo variavel (x=30) dias antes do vencimento:    sinalizar linha com o background laranja quando vencido até (x=30) dias do vencimento;    sinalizar linha com o background vermelho depois de variavel (x=30) dias do vencimento. vermelho #ffc9c9; e quando maior que x=30 dias de vencido não listar na tabela. </li>'+
    '<li>Desabilitar e habilitar contribuinte - quando habilitado se for de outra contabilidade permitir o cadastro e permanecer nas duas</li>'+
    '<li>Alterar Textos Uploads</li>'+
    '<li>Correcao do links de e-mail dev homo prod</li>'+
    '<li>Quando usuário logar e não existir Gestor (Contabilidade) jogar direto no cadastro da contabilidade, não permitindo avançar antes de cadastrar! </li>'+
    '<li>Criar rotina para disparo de emails no backend via php</li>'+
  '</ul>'
  +'</li>'+ 
  '<li>Dev 1.0.3'+
  '<ul>'+
    '<li>Quando um usuario logar abrir o modal alterar gestor (trocar texto para Bem-Vindo selecione uma contabilidade) + tirar opção de continuar + tirar a opção de fechar</li>'+
    '<li>Construir grafico como o da auditoria com os uploads do rvi + filtro por status e data - separar por tipo de upload (PVA, CSV, NFE, PDV)</li>'+
    '<li>Alterar tabela rvi, acrescentar TIPO, USUÁRIO e DATA STATUS</li>'+
    '<li>Verifciar biblioteca do input upload para arrumar css + quando arquivo selecionado omitir nome e colocar na tooltip mas no html deixar x arquivos selecionados.</li>'+
    '<li>Quando clicar no grafico mostrar uma gif e scrool down para a tabela dos uploads mostrando os resultados</li>'+
    '<li>Colocar collapse na parte de Uploads + icone do lado direito mostrando quando aberto e fechado.</li>'+
    '<li>Ver o filtro do gráfico modificar opção de visualização quando clicar no nome do tipo;</li>'+
    '<li>Verificar se existe a opção de ajustar a largura das colunas nas tabelas com o mouse;</li>'+
    '<li>Configurar o layout para adaptativo deixando o box do grafico 100% + filtro em cima e menus na lateral direita (Geral, verificar todas as telas e deixar padrão na construção)</li>'+
    '<li>Colocar collapse em todos os card do projeto.</li>'+
    '<li>Ajustar os formulário para o responsivo.</li>'+
    '<li>Colocar os modais de add e alterar em width 100% + max-width: 80vw;</li>'+
    '<li>Downgrade, verificar a qtd de contribuinte e dar a opção de deletar os contribuintes excedentes para o novo plano.</li>'+
    '<li>Verificar o status da contabilidade/contribuinte no option selecionado e se status = pendente mostrar a tela de alteração para o mesmo.</li>'+
    '<li>Atualizar Contribuinte e Atualizar Contabilidade - colocar lupa para consultar cnpj e cep.</li>'+
    '<li>quando selecionar registro do rvi, mostrar modal com todos os campos (verificar template com o thiago);</li>'+
    '<li>criar 2 gráficos pizza: 1 total com ean e sem ean; 2 total com ncm e sem ncm; exportar produtos sem ou com ean e sem ncm</li>'+
    '</ul>'
  +'</li>'+ 
  '<li>Dev 1.0.2'+
    '<ul>'+
    '<li>Verificar para deixar o material adptativo.</li>'+
    '<li>Trocar Planos para: até 2 cnpjs; de 2 até 10;</li>'+
    '<li>verificar a validação dos forms e não permitir passar sem preencher.</li>'+
    '<li>revisão caso contribuinte pendente trazer tela de alteração para completar cadastro e não consultar.</li>'+
    '<li>enviar flag para mudar a validação do atualizar o contribuinte não obrigatório (cep,endereço,número,complemento,bairro, grupo economico) mudar texto endereco para endereço e tirar obrigatoriedade do grupo economico.</li>'+
    '<li>trocar XLSX por CSV no rvi e alinhar os botões export.</li>'+
    '<li>aumertar o select contribuinte deixando exibir mais informações do nome e alinhar os campos.</li>'+
    '</ul>'+
  '</li>'+
  '<li>Dev 1.0.1'+
    '<ul>'+
    '<li>Aviso de pendentes precisa sumir sem f5 quando o usuário terminar de completar cadastro da contabilidade e contribuinte</li>'+
    '<li>erro ao desabilitar a contabilidade</li>'+
    '<li>ao excluir um usuário de contabilidade e reativa-lo, mensagem "esse usuário foi cadastrado mas está desativado, deseja reativa-lo?"</li>'+
    '<li>erro ao tentar reativar usuários de contabilidade</li>'+
    '<li>Atualizar de 15 em 15 segundos a grade de  certificados - se não houver alteração manter a tela </li>'+
    '<li>erro ao cadastrar um usuário de contribuinte em dois ou mais contribuintes, aparece apenas em 1</li>'+
    '<li>CSV aceitar apenas zip </li>'+
    '<li>Rodapé com alterações</li>'+
    '</ul>'+
    '</li>'+
  '</ul>'
})
export class DialogDataExampleDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
