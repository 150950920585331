import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfigConst } from 'src/app/core/util/config.const';
import { UserUtil } from 'src/app/core/util/user.util';
import { UpgradeModalComponent } from 'src/app/view/components/upgrade-modal/upgrade-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModuloService {
  API = ConfigConst.API;
  userUtil = new UserUtil();
  constructor(private httpClient: HttpClient, private dialog: MatDialog) {}

  getPermissoesModulo(id: string): Observable<any[]> {
    var headers = new HttpHeaders({
      Authorization:
        'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
      'Content-Type': 'application/json',
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });
    return this.httpClient.get<any[]>(
      ConfigConst.API + 'Gestor/SelectGestorContrato/' + id,
      { headers }
    );
  }

  getPermission(id) {
    this.getPermissoesModulo(id).subscribe(
      (res) => {
        // console.log(res);

        localStorage.removeItem('calSNAC');
        localStorage.removeItem('conEAN');
        localStorage.removeItem('detAUD');
        localStorage.removeItem('envRVIP');
        localStorage.removeItem('envRVIR');
        localStorage.removeItem('logCLI');
        localStorage.removeItem('relREF');
        localStorage.removeItem('segSNAC');
        localStorage.removeItem('uplCSV');
        localStorage.removeItem('uplNFEP');
        localStorage.removeItem('uplNFES');
        localStorage.removeItem('uplNFEX');
        localStorage.removeItem('uplPDV');
        localStorage.removeItem('uplPVA');
        localStorage.removeItem('uplXML');

        if (res['status']['resultado']) {
          localStorage.removeItem('limite_cnpj');
          localStorage.removeItem('limite_cnpj_entrada');
          localStorage.removeItem('limite_prod');
          localStorage.removeItem('limite_xml');
          localStorage.removeItem('QntRecuperacao');

          localStorage.setItem('limite_cnpj', res['data']['LimiteCNPJModulo']);
          localStorage.setItem(
            'limite_cnpj_entrada',
            res['data']['LimiteCNPJEntrada']
          );
          localStorage.setItem('limite_prod', res['data']['LimiteProd']);
          localStorage.setItem('limite_xml', res['data']['LimiteXML']);
          localStorage.setItem('QntRecuperacao', res['data']['QntRecuperacao']);

          var dados = res['data'];
          for (
            var j = 0, length = dados['Funcionalidade']?.length ?? 0;
            j < length;
            j++
          ) {
            localStorage.removeItem(
              res['data']['Funcionalidade'][j]['Funcionalidade']
            );
            localStorage.setItem(
              res['data']['Funcionalidade'][j]['Funcionalidade'],
              'S'
            );
            // console.log(res['data']['Funcionalidade'][j]['Funcionalidade']+"=F");
          }
        }
      },
      (err) => {
        console.error(err);
      }
    );
    //  console.log(localStorage);
  }

  SelectModuloGestorContribuinte() {
    return this.httpClient.get<any[]>(
      `${
        ConfigConst.API
      }Modulo/SelectModuloGestorContribuinte/${localStorage.getItem('modulo')}`,
      {
        headers: {
          Authorization:
            'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
        },
      }
    );
  }

  SelectModuloGestorContribuinteDisponivel() {
    return this.httpClient.get<any[]>(
      `${
        ConfigConst.API
      }Modulo/SelectModuloGestorContribuinteDisponivel/${localStorage.getItem(
        'modulo'
      )}`,
      {
        headers: {
          Authorization:
            'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
        },
      }
    );
  }

  InsertModuloGestorContribuinte(CNPJ_Contribuinte: string, moduloId?: string) {
    return this.httpClient.post<any[]>(
      `${ConfigConst.API}Modulo/InsertModuloGestorContribuinte/${
        moduloId ?? localStorage.getItem('modulo')
      }/${CNPJ_Contribuinte}`,
      {},
      {
        headers: {
          Authorization:
            'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
        },
      }
    );
  }

  getModulos() {
    var headers = new HttpHeaders({
      Authorization:
        'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
      'Content-Type': 'application/json',
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });
    // console.log(headers);
    // console.log(ConfigConst.API+"Gestor/SelectGestorModuloContrato");
    return this.httpClient.get<any[]>(
      ConfigConst.API + 'Gestor/SelectGestorModuloContrato',
      { headers }
    );
  }

  buscaSubmoduloDisponivel(ModuloId: string, SubModuloId: string) {
    return this.httpClient.get<any[]>(
      ConfigConst.API + 'Modulo/SelectSubModuloGestorDisponivel',
      {
        params: {
          ModuloId,
          SubModuloId,
        },
        headers: {
          Authorization:
            'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
          'Content-Type': 'application/json',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
        },
      }
    );
  }

  temModulo(id) {

    const isSustentacao = [
      ConfigConst.emailSustentacao,
      ConfigConst.emailSustentacaoComercial,
    ].includes(localStorage.getItem('email_login')) || localStorage.getItem('email_login')?.includes("@itworks.com");

    var modulos = [];
    this.getModulos().subscribe((res) => {
      console.log('RETORNO MODULO AQUI ========>', res);
      if (res['data'] !== null) {
        for (var i = 0, len = res['data'].length; i < len; i++) {
          modulos.push(res['data'][i]['ModuloId']);
        }
      }
      if (modulos.includes(Number(id)) || isSustentacao) {
        return true;
      } else if (Number(id) == 6) {
        const dialogRef = this.dialog.open(UpgradeModalComponent, {
          width: '80vw',
          maxWidth: '500px',
          maxHeight: '90vh',
          data: {
            title: 'Aviso!',
            subtitle: ConfigConst.SubTituloUpgrade,
            text: ConfigConst.TextoContratar,
            contratar: true,
          },
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (!res) {
            window.location.href = '/dashboard';
          } else {
            window.location.href = '/contratar-pacote';
          }
        });
      } else {
        const dialogRef = this.dialog.open(UpgradeModalComponent, {
          width: '80vw',
          maxWidth: '500px',
          maxHeight: '90vh',
          data: {
            title: 'Aviso!',
            subtitle: ConfigConst.SubTituloUpgrade,
            text: ConfigConst.TextoUpgrade,
          },
        });
        dialogRef.afterClosed().subscribe(() => {
          window.location.href = '/dashboard';
        });
      }
    });
  }
}
