import { Component, OnInit } from '@angular/core';
import { GridChart } from '../../model/grid.model';
import { UploadsService } from '../../services/uploads.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data-chart.service';
import { GridEtapa } from '../../model/grid-etapa.model';
import { DataEtapaService } from '../../services/data-etapa.service';

@Component({
  selector: 'app-etapa-info',
  templateUrl: './etapa-info.component.html',
  styleUrls: ['./etapa-info.component.css'],
})
export class EtapaInfoComponent implements OnInit {
  dataSource: Array<GridEtapa> = [];
  grid: Array<GridEtapa>;
  tipo: any;
  displayedColumns: string[] = [
    'contribuinte',
    'competencia',
    'tipo',
    'dataStatus',
    'mensagem',
  ];
  constructor(
    private uploadService: UploadsService,
    private router: Router,
    private dataEtapa: DataEtapaService,
    private activateRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.tipo = this.activateRoute.snapshot.paramMap.get('tipo');
    this.dataSource = this.dataEtapa.getDataEtapa();
  }

  toHome() {
    this.router.navigate(['/dashboard/etapas']);
  }
}
