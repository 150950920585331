import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserUtil } from 'src/app/core/util/user.util';
import { Observable } from 'rxjs';
import { GestorUsuario } from '../model/gestorusuario.model';
import { ConfigConst } from 'src/app/core/util/config.const';

@Injectable({
  providedIn: 'root',
})
export class GestorUsuarioService {
  resource = 'gestorusuario';
  uri = ConfigConst.baseURL + this.resource;
  baseUri = `${ConfigConst.AWSImpostograma}/${ConfigConst.APP}/${this.resource}`;
  authorization = ConfigConst.AWSAuth;
  userUtil = new UserUtil();

  constructor(private httpClient: HttpClient) {}

  getGestorByEmail(email): Observable<any> {
    //console.log(email);
    return this.httpClient.get<any>(
      this.baseUri + '/buscarGestorUsuarioEmail/' + email,
      {
        headers: {
          Authorization: this.authorization,
        },
      }
    );
  }

  getGestorByCNPJ(cnpj): Observable<any> {
    return this.httpClient.get<any>(
      this.uri + '/buscarGestorUsuarioCnpj/' + cnpj,
      {
        headers: {
          Authorization: this.authorization,
        },
      }
    );
  }

  updateGestorUsuario(gestorusuario: GestorUsuario): Observable<any> {
    return this.httpClient.post<any>(this.uri + '/atualizar', gestorusuario);
  }

  atualizarGestorUsuario(gestorusuario: GestorUsuario): Observable<any> {
    return this.httpClient.post<any>(this.uri + '/update', gestorusuario);
  }

  mudancaGestorUsuario(gestorusuario: any): Observable<any> {
    return this.httpClient.post<any>(this.uri + '/mudanca', gestorusuario);
  }

  desabilitarGestorUsuario(cnpj: any): Observable<any> {
    return this.httpClient.post<any>(this.uri + '/desabilitar', {
      CNPJ_Gestor: cnpj,
    });
  }

  listarusuariogestor(cnpj: any): Observable<any> {
    return this.httpClient.get<any>(this.uri + '/usuariogestor/' + cnpj);
  }

  deletarGestorUsuario(cnpj: any, email: any): Observable<any> {
    return this.httpClient.post<any>(this.uri + '/deletar', {
      CNPJ_Gestor: cnpj,
      EmailLogin: email,
    });
  }

  permissoesGestor(cnpj: string): Observable<any> {
    return this.httpClient.get(this.uri + '/permissoes/' + cnpj);
  }
}
