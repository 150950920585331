import { ConfigConst } from 'src/app/core/util/config.const';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  resource = '/email';
  uri = ConfigConst.AWSImpostograma + this.resource;
  authorization = ConfigConst.AWSAuth;

  constructor(private HttpClient: HttpClient) {}

  enviaFundamentacaoLegal(payload: any): Observable<any> {
    
    return this.HttpClient.post(
      this.uri + '/solicitarFundamentacaoLegal',
      payload,
      {
        headers: {
          Authorization: this.authorization,
          db_name: localStorage.getItem('database') ?? '',
          email_login: localStorage.getItem('email_login'),
        },
      }
    );
  }
}
