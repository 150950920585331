<h1 mat-dialog-title>{{ data.title }}</h1>
<h3>{{ data.subtitle }}</h3>
<div>
  <p>
    {{ data.text }}
  </p>
</div>
<div>
  <button
    *ngIf="!contratar"
    class="upbutton"
    mat-raised-button
    color="primary"
    (click)="upgrade()"
  >
    Fazer Upgrade
  </button>
  <button
    *ngIf="contratar"
    class="upbutton"
    mat-raised-button
    color="primary"
    (click)="contratarPlano()"
    [routerLink]="['/contratar-pacote']"
  >
    Contratar Pacote
  </button>
  <button class="upbutton" mat-raised-button color="warn" mat-dialog-close>
    Fechar
  </button>
</div>
