<app-loading *ngIf="loading"></app-loading>

<h1 class="title">Adicionar {{ Contabilidade }}</h1>

<mat-vertical-stepper [linear]="isLinear" #stepper>
  <mat-step
    [stepControl]="firstFormGroup"
    label="Informe os dados {{ daContabilidade }}"
  >
    <form #g="ngForm" [formGroup]="firstFormGroup">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <mat-form-field
          class="col-lg-6 col-sm-6 col-xs-12"
          appearance="outline"
        >
          <mat-label>CNPJ</mat-label>
          <input
            matInput
            formControlName="CNPJ_Gestor"
            mask="00.000.000/0000-00"
            [(ngModel)]="gestor.CNPJ_Gestor"
            placeholder="CNPJ"
            type="text"
            name="CNPJ_Gestor"
            required
          />
        </mat-form-field>
        <mat-form-field
          class="col-lg-6 col-sm-6 col-xs-12"
          appearance="outline"
        >
          <mat-label>Razão Social</mat-label>
          <input
            matInput
            formControlName="Razao_Social"
            [(ngModel)]="gestor.Nome"
            placeholder="Razão Social"
            type="Text"
            name="Razao_Social"
            maxlength="50"
            required
          />
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <mat-form-field
          class="col-lg-6 col-sm-6 col-xs-12"
          appearance="outline"
        >
          <mat-label>CEP</mat-label>
          <input
            matInput
            formControlName="CEP"
            mask="00000-000"
            (focusout)="buscacep()"
            [(ngModel)]="gestor.CEP"
            placeholder="CEP"
            type="Text"
            name="CEP"
            required
          />
          <mat-icon class="icone" matSuffix (click)="buscacep()"
            >search</mat-icon
          >
        </mat-form-field>
        <mat-form-field
          class="col-lg-6 col-sm-6 col-xs-12"
          appearance="outline"
        >
          <mat-label>Endereco</mat-label>
          <input
            matInput
            formControlName="Endereco"
            [(ngModel)]="gestor.Endereco"
            placeholder="Endereco"
            required
            maxlength="50"
          />
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <mat-form-field
          class="col-lg-6 col-sm-6 col-xs-12"
          appearance="outline"
        >
          <mat-label>Número</mat-label>
          <input
            matInput
            formControlName="Numero"
            [(ngModel)]="gestor.Nro"
            placeholder="Numero"
            required
            maxlength="10"
          />
        </mat-form-field>
        <mat-form-field
          class="col-lg-6 col-sm-6 col-xs-12"
          appearance="outline"
        >
          <mat-label>Complemento</mat-label>
          <input
            matInput
            formControlName="Complemento"
            [(ngModel)]="gestor.Complemento"
            placeholder="Complemento"
            maxlength="50"
          />
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <mat-form-field
          class="col-lg-6 col-sm-6 col-xs-12"
          appearance="outline"
        >
          <mat-label>Cidade</mat-label>
          <input
            matInput
            formControlName="Cidade"
            [(ngModel)]="gestor.Cidade"
            placeholder="Cidade"
            required
            maxlength="50"
          />
        </mat-form-field>
        <mat-form-field
          class="col-lg-6 col-sm-6 col-xs-12"
          appearance="outline"
        >
          <mat-label>UF</mat-label>
          <mat-select formControlName="UF" [(ngModel)]="gestor.UF">
            <mat-option *ngFor="let uf of ufList" [value]="uf">{{
              uf
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <mat-form-field
          class="col-lg-6 col-sm-6 col-xs-12"
          appearance="outline"
        >
          <input
            matInput
            formControlName="NomeContato"
            [(ngModel)]="gestor.ContatoNome"
            placeholder="Nome Contato"
            required
            maxlength="50"
          />
        </mat-form-field>
        <mat-form-field
          class="col-lg-6 col-sm-6 col-xs-12"
          appearance="outline"
        >
          <input
            matInput
            formControlName="EmailContato"
            [(ngModel)]="gestor.ContatoEmail"
            placeholder="E-mail Contato"
            required
            email
            maxlength="80"
          />
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <mat-form-field
          class="col-lg-6 col-sm-6 col-xs-12"
          appearance="outline"
        >
          <mat-label>Telefone</mat-label>
          <input
            matInput
            formControlName="Telefone"
            mask="(00) 0000-0000"
            [(ngModel)]="gestor.Telefone"
            placeholder="Telefone"
            type="Text"
            name="Telefone"
            maxlength="15"
            required
          />
        </mat-form-field>
        <mat-form-field
          class="col-lg-6 col-sm-6 col-xs-12"
          appearance="outline"
        >
          <input
            [(ngModel)]="gestor.Senha"
            name="p"
            id="p"
            matInput
            placeholder="Senha"
            [type]="hide ? 'password' : 'text'"
            formControlName="Senha"
            required
          />
          <button
            color="primary"
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="top">
        <!-- <button mat-button [disabled]="!g.valid" matStepperNext>Próximo</button> -->
        <button
          mat-raised-button
          color="primary"
          [disabled]="!g.valid"
          matStepperNext
        >
          Selecionar Plano
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step label="Selecione um Plano">
    <div class="plan">
      <div
        class="example-ripple-container card-plano mat-elevation-z4 {{
          planoId == '1' ? 'example-ripple-container-hover' : ''
        }}"
        id="plan1"
        (click)="planoId = '1'"
      >
        Plano Trial - Acesso Reduzido
      </div>
      <div
        class="example-ripple-container card-plano mat-elevation-z4 {{
          planoId == '2' ? 'example-ripple-container-hover' : ''
        }}"
        id="plan2"
        (click)="planoId = '2'"
      >
        Plano Premium - Acesso Completo
      </div>
    </div>
    <div class="top">
      <button
        *ngIf="planoId == '2'"
        mat-raised-button
        color="primary"
        (click)="adicionarContabilidade()"
      >
        Cadastrar
      </button>
      <button
        *ngIf="planoId == '1'"
        mat-raised-button
        color="primary"
        matStepperNext
      >
        Proximo
      </button>
    </div>
  </mat-step>
  <mat-step *ngIf="planoId == '1'" label="Sobre a POC">
    <form #g="ngForm" [formGroup]="secondFormGroup">
      <div *ngFor="let data of gestorContribuintePOC; index as i">
        <div class="col-lg-12 col-sm-12 col-xs-12">
          <mat-form-field
            class="col-lg-6 col-sm-6 col-xs-12"
            appearance="outline"
          >
            <mat-label>CNPJ do Contribuinte</mat-label>
            <input
              matInput
              [formControlName]="'CNPJ_Contribuinte' + i"
              mask="00.000.000/0000-00"
              [(ngModel)]="gestorContribuintePOC[i].CNPJ_Contribuinte"
              placeholder="CNPJ do Contribuinte"
              type="text"
              name="CNPJ_Contribuinte"
              required
            />
          </mat-form-field>
          <mat-form-field
            class="col-lg-6 col-sm-6 col-xs-12"
            appearance="outline"
          >
            <mat-label>Tipo de Documento</mat-label>
            <mat-select
              [formControlName]="'Tipo' + i"
              [value]=""
              [(ngModel)]="gestorContribuintePOC[i].Tipo"
              name="Tipo"
            >
              <mat-option
                *ngFor="let option of selectOptionsTipos"
                [value]="option"
                >{{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 col-sm-12 col-xs-12">
          <mat-form-field
            class="col-lg-4 col-sm-4 col-xs-12"
            appearance="outline"
          >
            <mat-label>Ano</mat-label>
            <mat-select
              [formControlName]="'Ano' + i"
              [value]=""
              [(ngModel)]="gestorContribuintePOC[i].Ano"
              name="Ano"
            >
              <mat-option
                *ngFor="let option of selectYears"
                [value]="option | date : 'yyyy'"
                >{{ option | date : "yyyy" }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="col-lg-4 col-sm-4 col-xs-12"
            appearance="outline"
          >
            <mat-label>Mes</mat-label>
            <mat-select
              [formControlName]="'Mes' + i"
              [value]=""
              [(ngModel)]="gestorContribuintePOC[i].Mes"
              name="Mes"
            >
              <mat-option
                *ngFor="let option of selectMonth"
                [value]="option.Valor"
                >{{ option.Nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="col-lg-4 col-sm-4 col-xs-12"
            appearance="outline"
          >
            <mat-label>Quantidade de Arquivos</mat-label>
            <input
              matInput
              [formControlName]="'QuantidadeArquivos' + i"
              [(ngModel)]="gestorContribuintePOC[i].QuantidadeArquivos"
              placeholder="Quantidade de Arquivos"
              type="number"
              name="QuantidadeArquivos"
              required
            />
          </mat-form-field>
        </div>
      </div>
      <div class="add-button text-center w-100 col-lg-12 col-sm-12 col-xs-12">
        <button
          mat-icon-button
          class="text-center"
          (click)="handleAddNovaConfiguracao()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div class="top">
        <!-- <button mat-button [disabled]="!g.valid" matStepperNext>Próximo</button> -->
        <button
          mat-raised-button
          color="primary"
          [disabled]="!g.valid"
          (click)="adicionarContabilidade()"
        >
          Cadastrar
        </button>
      </div>
    </form>
  </mat-step>
</mat-vertical-stepper>
