import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, Optional } from '@angular/core';

export interface DialogData {
  title: string;
  message: string;
  button?: boolean;
  cancel?: boolean;
  useHtml?: boolean;
  observacao?: string;
  checkBoxTitle?: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  dialogData: DialogData;
  title: string;
  message: string;
  observacao: string;
  useHtml: boolean = false;
  showbuttons: boolean = true;
  showCancelButton: boolean = false;
  checkBoxTitle: string;

  checkBoxValue: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.useHtml = this.data.useHtml;

    this.checkBoxTitle = this.data.checkBoxTitle;

    this.observacao = this.data.observacao ?? '';

    if (this.data.button == false) {
      this.showbuttons = false;
    }

    if (this.data.cancel) {
      this.showCancelButton = true;
    }
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  onCancel(): void {
    // Close the dialog, return false
    this.dialogRef.close('cancelar');
  }
}
