import { GoogleAnalyticsService } from 'src/app/shared/services/googleanalytics.service';
import { UploadArq } from './../model/uploads.model';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigConst } from '../../core/util/config.const';
import { GridChart } from '../model/grid.model';
import { UserUtil } from 'src/app/core/util/user.util';
import { CustomValidators } from '../validators/validators';

@Injectable({
  providedIn: 'root',
})
export class UploadsService {
  resource = 'uploads/';
  uri = ConfigConst.baseURL + this.resource;
  emailLogin: string;
  userUtil = new UserUtil();
  uploadEvent = new EventEmitter<boolean>();

  constructor(
    private httpClient: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.emailLogin = this.userUtil.getEmailLogin();
    //console.log('gestor upload ' + this.userUtil.getCnpjGestor());
  }

  setUploadEvent(event: any) {
    this.uploadEvent.emit(event);
  }

  getCharts(): Observable<UploadArq[]> {
    if (this.userUtil.getCnpjGestor())
      return this.httpClient.get<UploadArq[]>(
        this.uri + 'gestor/' + this.userUtil.getCnpjGestor()
      );
  }

  VerArquivos($contribuinte, $ano, $mes, $tipo) {
    return this.httpClient.get<any>(
      this.uri +
        'verarquivos/' +
        $contribuinte +
        '/' +
        $ano +
        '/' +
        $mes +
        '/' +
        $tipo
    );
  }

  getStatusAndData(status, startData, endData, tipo): Observable<any> {
    if (typeof status === 'undefined' || status.length <= 0) {
      status = [0];
    }

    if (startData === 'Invalid date') {
      startData = '0';
    }

    if (endData === 'Invalid date') {
      endData = '0';
    }

    if (tipo === 'undefined') tipo = 'ALL';

    if (this.userUtil.getCnpjGestor())
      return this.httpClient.get<any>(
        this.uri +
          'chart/' +
          tipo +
          '/status/' +
          status +
          '/start/' +
          startData +
          '/end/' +
          endData +
          '/gestor/' +
          this.userUtil.getCnpjGestor(),
        {
          reportProgress: true,
          observe: 'events',
        }
      );
  }

  showGridChart(event, status): Observable<GridChart[]> {
    if (this.userUtil.getCnpjGestor())
      return this.httpClient.get<GridChart[]>(
        `${this.uri}grid/${event}` +
          '/status/' +
          status +
          '/gestor/' +
          this.userUtil.getCnpjGestor()
      );
    console.log(
      `${this.uri}grid/${event}` +
        '/status/' +
        status +
        '/gestor/' +
        this.userUtil.getCnpjGestor()
    );
  }

  submitFiles(file: File, type: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('gestor', this.userUtil.getCnpjGestor());
    formData.append('email', this.userUtil.getEmailLogin());
    formData.append('type', type);

    return this.httpClient.post(this.uri + 'files', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  getCredencial(): Observable<any> {
    const formData = new FormData();
    formData.append('usuario', 'userImpostoBR');
    formData.append('senha', 'Im@st@10');

    // return this.httpClient.post<any>('https://app1.rfdmonitor.com.br/servicos/Impostocerto/api/Token/Novo?usuario=userImpostoBR&senha=Im@st@10',formData,{
    return this.httpClient.post<any>(
      ConfigConst.API + 'Token/Novo?usuario=userImpostoBR&senha=Im@st@10',
      formData
    );
  }

  UploadCertificado(
    file: File,
    senha: string,
    isContabilidade: boolean = false
  ): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: ' Bearer TESTE',
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });

    let endpoint = 'UploadCertificado';

    if (isContabilidade) {
      endpoint += 'Gestor';
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('senha-certificado', senha);

    return this.httpClient.post<any>(
      `${ConfigConst.Emalote}/api/Arquivo/${endpoint}`,
      formData,
      { headers, reportProgress: true, observe: 'events' }
    );
  }

  UploadArquivo(gestor, tipo, modulo, credencial, file): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: ' Bearer ' + credencial,
      // 'Authorization':'Bearer YT0zYTFmMDI4ZC1hOWE2LTQ0YzItYWJhMy03MGUzNmM3MTg2ZTUsOTU9MTQ5'
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });

    const formData = new FormData();
    formData.append('01', file);
    console.log(ConfigConst.API + 'Arq/Upload/' + tipo + '/' + modulo);
    return this.httpClient.post<any>(
      ConfigConst.API + 'Arq/Upload/' + tipo + '/' + modulo,
      formData,
      { headers, reportProgress: true, observe: 'events' }
    );
  }

  UploadChaveArquivo(file, cnpj): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: 'RRwPrJsGdiwdWZ1CZj9srRtCdQ99LPeg',
      type: 'file',
      cnpj: cnpj,
    });

    const formData = new FormData();
    formData.append('', file);

    return this.httpClient.post<any>(
      ConfigConst.AWS + 'uploads-' + ConfigConst.Ambiente + '/uploadKeysNFe',
      formData,
      { headers, reportProgress: true, observe: 'events' }
    );
  }

  UploadChaves(lista, cnpj): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: 'RRwPrJsGdiwdWZ1CZj9srRtCdQ99LPeg',
      type: 'array',
      cnpj: cnpj,
    });

    const payload = { keys: lista };

    return this.httpClient.post<any>(
      ConfigConst.AWS + 'uploads-' + ConfigConst.Ambiente + '/uploadKeysNFe',
      payload,
      { headers }
    );
  }

  UploadPVAChaves(url, cnpj): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: 'RRwPrJsGdiwdWZ1CZj9srRtCdQ99LPeg',
      type: 'pva',
      cnpj: cnpj,
    });

    const payload = { fileUrl: url };
    // console.log(payload);
    return this.httpClient.post<any>(
      ConfigConst.AWS + 'uploads-' + ConfigConst.Ambiente + '/uploadKeysNFe',
      payload,
      { headers, reportProgress: true, observe: 'events' }
    );
  }

  sizeUpload() {
    return this.httpClient.get(this.uri + 'sizeUpload');
  }

  UploadSped(gestor, tipo, modulo, credencial, file): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: ' Bearer ' + credencial,
      // 'Authorization':'Bearer YT0zYTFmMDI4ZC1hOWE2LTQ0YzItYWJhMy03MGUzNmM3MTg2ZTUsOTU9MTQ5'
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });

    const formData = new FormData();
    formData.append('01', file);

    console.log(ConfigConst.Emalote + '/api/Arquivo/SpedUpload');
    console.log(
      'Authorization: Bearer ' + credencial,
      'email_login:' + localStorage.getItem('email_login'),
      'cnpj_gestor:' + localStorage.getItem('cnpj_gestor')
    );

    return this.httpClient.post<any>(
      ConfigConst.Emalote + '/api/Arquivo/SpedUpload',
      formData,
      { headers, reportProgress: true, observe: 'events' }
    );
  }

  handleButtonAnalytics(action: string) {
    this.googleAnalyticsService.sendEmitter({
      eventAction: action,
      eventCategory: 'Upload',
      eventLabel: CustomValidators.validarModulo(),
    });
  }

  UploadCSV(contribuinte, tipo, modulo, credencial, file): Observable<any> {
    this.handleButtonAnalytics('Upload Arquivo CSV');
    let headers = new HttpHeaders({
      Authorization: ' Bearer ' + credencial,
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });

    const formData = new FormData();
    formData.append('01', file);
    return this.httpClient.post<any>(
      ConfigConst.API + 'UploadCsv/CsvUpload/' + contribuinte,
      formData,
      { headers, reportProgress: true, observe: 'events' }
    );
  }

  ValidarCSV(contribuinte, credencial): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: ' Bearer ' + credencial,
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });

    console.log(ConfigConst.API + 'UploadCsv/CsvValidar/' + contribuinte);
    return this.httpClient.get<any>(
      ConfigConst.API + 'UploadCsv/CsvValidar/' + contribuinte,
      { headers }
    );
  }

  buscaStatusCSV(contribuinte: string): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: ConfigConst.AWSAuth,
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });

    return this.httpClient.post<any>(
      `${ConfigConst.AWSUploads}/${ConfigConst.APP}/consultaStatusUploadCSV`,
      {
        CNPJ_Contribuinte: contribuinte,
      },
      { headers }
    );
  }

  ImportarCSV(contribuinte: string, credencial: string): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: ' Bearer ' + credencial,
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });

    console.log(ConfigConst.API + 'UploadCsv/CsvImportar/' + contribuinte);
    return this.httpClient.post<any>(
      ConfigConst.API + 'UploadCsv/CsvImportar/' + contribuinte,
      {},
      { headers }
    );
  }

  VincularCSV(contribuinte, credencial, cols): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: ' Bearer ' + credencial,
      'Content-Type': 'application/json',
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });

    console.log(ConfigConst.API + 'UploadCsv/CsvVincular/' + contribuinte);
    console.log(cols);
    return this.httpClient.post<any>(
      ConfigConst.API + 'UploadCsv/CsvVincular/' + contribuinte,
      cols,
      { headers }
    );
  }

  ListarCSV(contribuinte, credencial, pagina, qtdpagina): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: ' Bearer ' + credencial,
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });

    console.log(
      ConfigConst.API +
        'UploadCsv/CsvListar/' +
        contribuinte +
        '?Pagina=' +
        pagina +
        '&QtdPorPagina=' +
        qtdpagina
    );
    return this.httpClient.get<any>(
      ConfigConst.API +
        'UploadCsv/CsvListar/' +
        contribuinte +
        '?Pagina=' +
        pagina +
        '&QtdPorPagina=' +
        qtdpagina,
      { headers }
    );
  }

  AprovarCSV(contribuinte, credencial): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: ' Bearer ' + credencial,
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });

    console.log(
      'Authorization: Bearer ' + credencial,
      'email_login: ' + localStorage.getItem('email_login'),
      'cnpj_gestor:' + localStorage.getItem('cnpj_gestor')
    );

    console.log(ConfigConst.API + 'UploadCsv/CsvAprovar/' + contribuinte);

    return this.httpClient.post<any>(
      ConfigConst.API + 'UploadCsv/CsvAprovar/' + contribuinte,
      {},
      { headers }
    );
  }
}
