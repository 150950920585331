import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  public GbUso;
  public GbTotal;
  public value;
}
