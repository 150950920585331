import { ConfigConst } from 'src/app/core/util/config.const';
import { GestorstorageService } from './../../services/gestorstorage.service';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/shared/validators/validators';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { UserUtil } from 'src/app/core/util/user.util';
import { EventService } from '../../services/event.service';
import { AuthService } from '../../services/auth.service';
import { GestorUsuarioService } from '../../services/gestor-usuario.service';
import { MessageService } from '../../services/message.service';

export interface DialogData {
  title: string;
  subtitle: string;
  text: string;
  gestores: Array<any>;
}

@Component({
  selector: 'app-dialog-modal',
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss'],
})
export class DialogModalComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() emailLogin: string;
  @Input() selectOptions: Array<string> = [];

  options = new FormControl();
  selectedGestor: string;
  gestores: Array<string> = [];
  gestoresJson: Array<string> = [];
  gestorToWork: string;
  gestorName: string;
  userUtil = new UserUtil();
  progress: boolean = false;

  Contabilidade = ConfigConst.Contabilidade;

  constructor(
    public dialogRef: MatDialogRef<DialogModalComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
    public eventComponent: EventService,
    private gestorUsuarioService: GestorUsuarioService,
    private messageService: MessageService,
    public gestorStorage: GestorstorageService
  ) {}

  public filteredList5 = [];
  public listgestores = [];

  formatarCNPJ(cnpj: string) {
    const cnpjspit = cnpj.split(' - ');
    return `${CustomValidators.formatarCNPJ(cnpjspit[0])} - ${cnpjspit[1]}`;
  }

  ngOnInit(): void {
    //this.setGestores();

    this.data.gestores.forEach((el) => {
      const CNPJ_GestorFormatado = CustomValidators.formatarCNPJ(
        el['CNPJ_Gestor']
      );
      this.listgestores.push({
        CNPJ_Gestor: el['CNPJ_Gestor'],
        Nome: el['Nome'],
        CNPJ_GestorFormatado,
        filtro:
          el['CNPJ_Gestor'] + ' - ' + el['Nome'] + ' - ' + CNPJ_GestorFormatado,
      });

      this.filteredList5.push({
        ...el,
        CNPJ_GestorFormatado,
      });
    });
  }

  handleFilterGestor(event: any) {
    this.filteredList5 = event;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setSelectedGestor(event) {}

  change(event) {
    if (event.isUserInput) {
      this.gestorToWork = event.source.value.CNPJ_Gestor;
      this.gestorName = event.source.value.Nome;
      localStorage.setItem('cnpj_gestor', this.gestorToWork);
      localStorage.setItem(
        'full_gestor',
        `${this.gestorToWork} - ${this.gestorName}`
      );
      this.gestorStorage.setPermissoes(this.gestorToWork);
      this.eventComponent.setEvent(true);
      this.progress = true;
      // this.router.navigate(['/dashboard']);
      // this.gestorStorage.setPermissoes(this.gestorToWork);
      // this.dialogRef.close();
      setTimeout(() => {
        document.location.reload();
      }, 3000);
    }
  }

  setGestores() {
    this.gestorUsuarioService
      .getGestorByEmail(btoa(this.userUtil.getEmailLogin()))
      .subscribe({
        next: (gestores) => {
          if (gestores.status) {
            this.messageService.showMessage(
              gestores.status,
              'warning-snackbar'
            );
          }
          this.gestores = gestores;
        },
        error: (err) => {
          this.messageService.showMessage(err.error.status, 'warning-snackbar');
        },
      });
  }
}
